import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import{ FindschoolComponent } from './authentication/findschool/findschool.component';
import { IloginComponent } from './authentication/ilogin/ilogin.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import {ForgetpasswordComponent} from './authentication/forgetpassword/forgetpassword.component';
import { InstitutionGuard } from './authentication/guards/institution.guard';
import { RandomGuard } from './authentication/guards/random.guard';


const routes: Routes = [
  {
    path: 'findschool',  component: FindschoolComponent
  },
  {
    path: 'forgetpassword',  component: ForgetpasswordComponent
  },
  {path: ':edumaq',  component: LoginComponent},
  {
    path: ':edumaq/login',
    component: LoginComponent,
    canActivate: [AuthGuard],   
  },
  {path: '', component: FindschoolComponent},

  {
    path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [InstitutionGuard],
   
  },
  {
    path: '', loadChildren: () => import('./edumaq/edumaq.module').then(m => m.EdumaqModule),
    canActivate: [RandomGuard],
   
  },
  {
    path: '', loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule),
    canActivate: [InstitutionGuard],
   
  },
  {
    path: '', loadChildren: () => import('./parentchild/parentchild.module').then(m => m.ParentchildModule),
    canActivate: [InstitutionGuard],
   
  },
  {
    path: '', loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule),
    canActivate: [InstitutionGuard],
   
  },
  {
    path: '', loadChildren: () => import('./institution/institution.module').then(m => m.InstitutionModule),
    canActivate: [InstitutionGuard],
   
  }




  // {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
