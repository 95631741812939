// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    li.mm-active a {
        color: #fff !important;
    }`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":";IACI;QACI,sBAAsB;IAC1B","sourcesContent":["\r\n    li.mm-active a {\r\n        color: #fff !important;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
