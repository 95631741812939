// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // timer: NodeJS.Timeout;
  constructor(
  private loaderService: LoaderService,
  private toastr: ToastrService,
  public authService: AuthenticationService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
    //this.loaderService.isLoading.next(true);
  }
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
        'Content-Type':'application/json'
        //'Content-Type':'*/*'
      }
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // console.log('sagar')
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getJwtToken()) {
      req = this.addToken(req, this.authService.getJwtToken());
    }

    if (req.headers.get('skip')) {
      return next.handle(req);
    }

    this.requests.push(req);

    this.loaderService.isLoading.next(true);

    if (req.headers.get('broadcast')) {
      setTimeout(() => {
        this.removeRequest(req);
      }, 3000);
    }

    return new Observable((observer: any) => {
      // console.log('observer',observer)
      // let isStopped = false
      
      const subscription = next.handle(req)
        .subscribe(
          event => {
            // console.log('abcdef',event)
            if (event instanceof HttpResponse) {
              this.loaderService.isLoading.next(false);
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            // alert('inter error' + err);
            // console.log(err);
            if (err != null) {
              // console.log('err',err)
              //debugger;
              if (err.status === 409) {
                this.toastr.warning(err.error, 'WARNING!!', {
                  timeOut: 5000
                });
              } 
              else if (err.status === 400) {
                this.toastr.error(err.error.Message, 'WARNING!!', {
                  timeOut: 5000
                });
              } 
               else {
                if(err.statusText) {
                  this.toastr.error(err.statusText, 'ERROR!!', {
                    timeOut: 5000
                  });
                }
              }
              this.removeRequest(req);
              observer.error(err);
            }
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}

