import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError, Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Tokens } from './models/tokens';
import { Authentication } from './authentication.model';
import { ForgetPasswordModel } from './models/forget-model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;
  public userType: string;
  public institutionCode: string;

  constructor(private httpClient: HttpClient) { }

  // login(username: any, password: any): Observable<any> {
  //   return this.httpClient.get<any>(environment.apiUrl + '/membership/login/' + username + '/' + password)
  //   // .map(res => res.json())
  //   // .catch(this.handleError);
  //   .pipe(
  //     tap(tokens => this.doLoginUser(username, tokens)),
  //     mapTo(true),
  //     catchError(this.errorHandler)
  //   );
  // }

  login(username: any, password: any): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + '/membership/login/' + username + '/' + password)
      .pipe(
       
        catchError(this.errorHandler)
        
      );
  }
  studentlogin(username: any, password: any, instcode: any): Observable<any> {
    const headers = new HttpHeaders()
    .set('InstitutionCode', instcode);
    let queryParams = new HttpParams();
    queryParams = queryParams.append("username",username);
    queryParams = queryParams.append("password",password);
    /*return this.httpClient.get<any>(environment.apiUrl + '/membership/schoollogin/' + username + '/' + password)*/
    return this.httpClient.get<any>(environment.apiUrl + '/membership/schoollogin',{ 'headers': headers,'params':queryParams })
    .pipe(
      catchError(this.errorHandler)
    );
  }
  teacherlogin(username: any, password: any, instcode: any): Observable<any> {   
    const headers = new HttpHeaders()
    .set('InstitutionCode', instcode);
    let queryParams = new HttpParams();
    queryParams = queryParams.append("username",username);
    queryParams = queryParams.append("password",password);
    //queryParams = queryParams.append("instcode",instcode);
    return this.httpClient.get<any>(environment.apiUrl + '/membership/teacherlogin/' + username + '/' + password ,{ 'headers': headers })
    // return this.httpClient.get<any>(environment.apiUrl + '/membership/teacherlogin',{ 'headers': headers ,'params':queryParams})
    // .pipe(
    //   catchError(this.errorHandler)
    // );
  }
  teacherloginToERP(username: any, password: any, instcode: any): Observable<any> {
   
    const headers = new HttpHeaders()
    .set('InstitutionCode', instcode);
    let queryParams = new HttpParams();
    queryParams = queryParams.append("username",username);
    queryParams = queryParams.append("password",password);
    //queryParams = queryParams.append("instcode",instcode);
    return this.httpClient.get<any>(environment.apiUrl + '/membership/teacherlogintoerp/' + username + '/' + password ,{ 'headers': headers })
    return this.httpClient.get<any>(environment.apiUrl + '/membership/teacherlogintoerp',{ 'headers': headers ,'params':queryParams})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  ilogin(username: any, password: any, instcode: any, usertype: any): Observable<any> {

    return this.httpClient.get<any>(environment.apiUrl + '/membership/institutionlogin/' + username + '/' + password    + '/' + instcode + '/' + usertype    )
    .pipe(
      catchError(this.errorHandler)
    );
  }


  logout() {
    return this.httpClient.post<any>(`${environment.apiUrl}/logout`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      tap(() => this.doLogoutUser()),
      mapTo(true),
      catchError(error => {
        alert(error.error);
        return of(false);
      }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.httpClient.post<any>(`${environment.apiUrl}/refresh`, {
      refreshToken: this.getRefreshToken()
    }).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
    }));
  }

  newToken(username, sessionExpiryTime) {
  
    return this.httpClient.get(`${environment.apiUrl}/membership/newtoken/${username}?sessionExpiryTime=${sessionExpiryTime}`)
      .pipe(
        tap((token: Tokens) => {
         this.storeJwtToken(token.jwt);
        })
      )
  }

  forgetPassword(form : ForgetPasswordModel){
    console.log('forgetPassword', form)
    let params = new HttpParams();
    params.append('username', form.username);
    params.append('usertype', form.usertype);
    params.append('instcode', form.schoolcode);

    return this.httpClient.get<any>(`${environment.apiUrl}/membership/ValidateUser/${form.username}/${form.usertype}/${form.schoolcode}`)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  isInstituteExist(instCode: string) {
    console.log('forgetPassword', instCode)
    return this.httpClient.get<string>(`${environment.apiUrl}/membership/IsInstituteExists/${instCode}`)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private doLoginUser(username: string, tokens: Tokens) {
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    window.localStorage.setItem(this.JWT_TOKEN, jwt);
    console.log(localStorage.getItem(this.JWT_TOKEN));
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);

  }

  errorHandler(error) {
    // console.log('error',error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
    console.log(errorMessage);
  }


}
