import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../authentication.service';
import { Authentication } from '../authentication.model';
import { SharedService } from '../../shared/shared.service';
import { LoginService } from './login.service';
import { LoginPageConfig } from '../../edumaq/configuration/loginpageconfig/loginpageconfig.model';
import { duration, invalid } from 'moment';
import { InstitutionprofileService } from '../findschool/institutionprofile.service';
import { environment } from 'src/environments/environment';
import { Institution } from 'src/app/admin/admin/institution.model';
import { AdminService } from 'src/app/admin/admin/admin.service';
import { InstitutionProfile } from '../findschool/institutionprofile.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader.service';
import { profile } from 'console';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { parseMarker } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  userName = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);
  imageSrc = '../assets/images/Logbanner/8.png';
  instCode = '';
  logoUrl = environment.imageUrl;
  instituteName: string = "";
  institution: Institution;
  loader: any = false;
  institutionProfile: InstitutionProfile;
  invalidErrorMessage: string = '';
  _iserror = false;
  private subscriptions: Subscription[];
  userIdChecked: boolean = false
  
  get iserror(): boolean {
    return this._iserror;
  }

  set iserror(value: boolean) {
    // console.log('setiserror', value);
    this._iserror = value;
    this.handleErrorMessage()
  }

  readonly loaderService=inject(LoaderService);
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profileService: InstitutionprofileService,
    private adminService: AdminService,
  ) { 
    this.subscriptions = new Array<Subscription>();
    this.instCode = this.activatedRoute.snapshot.params.edumaq;
    // console.log(' this.instCode', this.instCode)
    this.loaderService.isLoading.next(false);
  }


  private handleErrorMessage() {
    if (this._iserror) {
      this.invalidErrorMessage = 'Username or Password is incorrect !';
      this.toastr.error(this.invalidErrorMessage);
      // this._iserror = false
      setTimeout(() => {
        this.invalidErrorMessage = '';
      }, 5000);
    }
  }

  // ListLoginPages() {
  //   this.loginService.getAll().subscribe((data: LoginPageConfig[]) => {
  //     if (data != null && data.length > 0) {
  //       this.imageSrc = data[0].image;
  //       console.log(data[0].image);
  //     }
  //   });
  // }

  login() {
    // debugger
    if (this.form.valid) {
      this.loader = true;
      // const userName = this.userName.value;
      // const password = this.password.value;
      const userName = this.form.get('userName').value;
      const password = this.form.get('password').value;
      localStorage.setItem('uname', userName);
      if (this.instCode === undefined) {
        this.toastr.warning('URL Not full', 'WARNING!!', {
          timeOut: 3000
        });
        console.log('URL not fiull if condition failded');
        return;
      }

      if (userName !== '' && password !== '') {

        if (this.instCode === 'edumaq') {
          // console.log('abcd')
          try {
            var sub = this.authService.login(userName, password).subscribe((response: any) => {
              const token = response.token;
  
              if (token != null) {
  
                localStorage.setItem('JWT_TOKEN', token);
  
  
                this.authService.institutionCode = this.instCode;
                this.router.navigate([this.instCode + '/edumaq']);
                return;
              } else {
                this.iserror = true;
              }
            }, (err) => {
              this.iserror = true;
            }); 
          } catch (error) {
            console.log(error, 'error..')
          }
          this.subscriptions.push(sub);
          this.loader = false;
        }

        else {

          if (userName.indexOf('EMP') != -1) {
            if (userName.indexOf('ERP') != -1) {
              var teacherLoginERPSub = this.authService.teacherloginToERP(userName, password, this.instCode).subscribe((response: any) => {
                const token = response.token;
                // console.log(response.user);
                if (token != null) {
                  // console.log(response.user.userType);
                  if (response.user.userType === 'Employee') {
                    localStorage.setItem('JWT_TOKEN', token);
                    //store the user information
                    localStorage.setItem('user_' + this.instCode, JSON.stringify(response.user));
                    ///
                    this.authService.institutionCode = this.instCode;
                    localStorage.setItem('Institution_' + this.instCode, JSON.stringify(response.institution));
                    ;
                    this.router.navigate([this.instCode + '/admin']);
                    /*if(userName.indexOf('ERP')  != -1){
                      this.router.navigate([this.instCode + '/admin']);
                    }else{
                      this.router.navigate([this.instCode + '/teacher']);
                    }*/

                  } else {
                    this.toastr.warning('Authorization Failed', 'WARNING!!', {
                      timeOut: 3000
                    });
                  }
                } else {
                  this.iserror = true;
                }
              }, (err) => {
                this.iserror = true;
              });
              this.subscriptions.push(teacherLoginERPSub);
              this.loader = false;
            } else {
              var teacherSub = this.authService.teacherlogin(userName, password, this.instCode).subscribe((response: any) => {
                const token = response.token;
                // console.log(response.user);
                if (token != null) {
                  // console.log(response.user.userType);
                  if (response.user.userType === 'Employee') {
                    localStorage.setItem('JWT_TOKEN', token);
                    //store the user information
                    localStorage.setItem('user_' + this.instCode, JSON.stringify(response.user));
                    ///
                    this.authService.institutionCode = this.instCode;
                    localStorage.setItem('Institution_' + this.instCode, JSON.stringify(response.institution));
                    ;
                    this.router.navigate([this.instCode + '/teacher']);
                    /*if(userName.indexOf('ERP')  != -1){
                      this.router.navigate([this.instCode + '/admin']);
                    }else{
                      this.router.navigate([this.instCode + '/teacher']);
                    }*/

                  } else {
                    this.toastr.warning('Authorization Failed', 'WARNING!!', {
                      timeOut: 3000
                    });
                  }
                } else {
                  this.iserror = true;
                }
              }, (err) => {
                this.iserror = true;
              });
              this.subscriptions.push(teacherSub);
            }
          } else {
            if (userName.indexOf('admin') != -1) {
              // console.log('login')
              var authSub = this.authService.ilogin(userName, password, this.instCode, 'admin').subscribe(
                (response: any) => {
                  // console.log('test login',response);
                  this.iserror = false;
                  const token = response.token;
                  // console.log(token);
                  // this.invalidLogin = false;
                  if (response.user != null) {
                    //this.authService.userType = response.user.userType;
                  }
                  // console.log(response.user.userType);
                  if (token != null) {
                    this.sharedService.institutionCode = this.instCode;
                    // console.log(response.user);
                    localStorage.setItem('Institution_' + this.instCode, JSON.stringify(response.user));
                    localStorage.setItem('JWT_TOKEN', token);
                    localStorage.setItem('user_' + this.instCode, JSON.stringify(response.user));
                    //dont remove this- Check with Santhosh - It will impact report
                    localStorage.setItem('Institution', JSON.stringify(response.user));
                    // sessionStorage.setItem('JWT_TOKEN', token);
                    this.authService.institutionCode = this.instCode;


                    switch (response.user.userType) {
                      case 'Super Admin':
                        this.router.navigate([this.instCode + '/superadmin']);
                        break;

                      case 'Admin':
                        this.router.navigate([this.instCode + '/admin']);
                        break;

                    }

                  } else {
                    this.iserror = true;
                  }
                },
                (err) => {
                  this.iserror = true;
                });
                // console.log('log',authSub)
               
              this.subscriptions.push(authSub);
              this.userIdChecked = true
              setTimeout(()=>{
                if(this.iserror !== false){
                  this.iserror = true;
                  this.userIdChecked = false

                }
              },2000)
            } 
            else if (userName.length === 5) 
            {
              // console.log('test login');
              
              var authSubs = this.authService.ilogin(userName, password, this.instCode, 'super admin').subscribe(
                (response: any) => {
                  // console.log('test login',response);
                  const token = response.token;
                  // console.log(token);
                  // this.invalidLogin = false;
                  if (response.user != null) {
                    //this.authService.userType = response.user.userType;
                  }
                  // console.log(response.user.userType);
                  if (token != null) {
                    this.sharedService.institutionCode = this.instCode;
                    // console.log(response.user);
                    localStorage.setItem('Institution_' + this.instCode, JSON.stringify(response.user));
                    localStorage.setItem('JWT_TOKEN', token);
                    localStorage.setItem('user_' + this.instCode, JSON.stringify(response.user));
                    //dont remove this- Check with Santhosh - It will impact report
                    localStorage.setItem('Institution', JSON.stringify(response.user));
                    // sessionStorage.setItem('JWT_TOKEN', token);
                    this.authService.institutionCode = this.instCode;


                    switch (response.user.userType) {
                      case 'Super Admin':
                        this.router.navigate([this.instCode + '/superadmin']);
                        break;

                      case 'Admin':
                        this.router.navigate([this.instCode + '/admin']);
                        break;

                    }

                  } else {
                    this.iserror = true;
                  }
                },
                (err) => {
                  this.iserror = true;
                });
              this.subscriptions.push(authSubs);
            }
            else {
              var studentsub = this.authService.studentlogin(userName, password, this.instCode).subscribe((response: any) => {
                const token = response.token;
                // console.log(response.user);
                if (token != null) {
                  // console.log(response.user.userType);
                  if (response.user.userType === 'Student') {
                    localStorage.setItem('JWT_TOKEN', token);
                    this.authService.institutionCode = this.instCode;
                    //store the user information
                    localStorage.setItem('user_' + this.instCode, response.user);
                    ///
                    this.router.navigate([this.instCode + '/parentchild']);
                  } else {
                    this.toastr.warning('Authorization Failed', 'WARNING!!', {
                      timeOut: 3000
                    });
                  }
                } else {
                  this.iserror = true;
                }
              }, (err) => {
                this.iserror = true;
              });
              this.subscriptions.push(studentsub);
            }
          }
        }

        // }
      } else {
        this.iserror = true;
      }
    }
    else {
      this.markFormGroupTouched(this.form);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  logout() {
    console.log('running');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('Institution_' + this.instCode);
    localStorage.removeItem('user_' + this.instCode);
    localStorage.clear();
    this.router.navigate(['/'], { relativeTo: this.activatedRoute });
  }

  ngOnInit(): void {
    this.loaderService.isLoading.next(true);
    this.form = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    localStorage.removeItem('uname');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('disAllowedPaths');
    
    // this.ListLoginPages();
    //this.instCode = this.activatedRoute.snapshot.params.edumaq;

    /* var profileSub = this.profileService.getInstaLogo(this.instCode).subscribe((data) => {
      console.log('profileService', data);
      if(data.institutionname === null && this.instCode != 'edumaq') {
          this.router.navigate(['/findschool']);
      } else {
        if(this.instCode == 'edumaq') {
          this.logoUrl = '../../../assets/images/Logo-00.png';
        } else {
          this.logoUrl += data.logoUrl || '../../../assets/images/Logo-00.png';
        }
        this.institutionProfile = data as InstitutionProfile;
        console.log('profileService', this.institutionProfile);
        this.instituteName = this.institutionProfile.institutionname;
      }
    });
    this.subscriptions.push(profileSub); */
    // console.log(this.instCode);
    // console.log(this.logoUrl);

    //this.logoUrl = '../../../assets/images/Logo-00.png';
  }

/* readonly dataSource$=this.profileService.getInstaLogo(this.activatedRoute.snapshot.params.edumaq).pipe(
  tap(e=>this.loaderService.isLoading.next(false)),
  map(data=>{
    if(data.institutionname === null && this.instCode != 'edumaq') {
      this.router.navigate(['/findschool']);
  } else {
    if(this.instCode == 'edumaq') {
      this.logoUrl = '../../../assets/images/Logo-00.png';
    } else {
      //this.logoUrl += data.logoUrl || '../../../assets/images/Logo-00.png';
      //this.logoUrl += data.logoUrl;
      data.logoUrl=this.logoUrl+data.logoUrl || '../../../assets/images/Logo-00.png';
    }
    this.institutionProfile = data as InstitutionProfile;
    console.log('profileService', this.institutionProfile);
    this.instituteName = this.institutionProfile.institutionname;
    return data;
  }
  }),
  tap(e=>this.loaderService.isLoading.next(false)),
  tap(e=>console.log('test pipe'+e))
); */

readonly dataSource$=this.activatedRoute.paramMap.pipe(
  switchMap((params)=>
    this.profileService.getInstaLogo(params.get('edumaq')).pipe(
      tap(e=>this.loaderService.isLoading.next(false)),
      map(data=>{
        if(data.institutionname === null && this.instCode != 'edumaq') {
          this.router.navigate(['/findschool']);
      } else {
        if(this.instCode == 'edumaq') {
          this.logoUrl = '../../../assets/images/Logo-00.png';
        } else {
          //this.logoUrl += data.logoUrl || '../../../assets/images/Logo-00.png';
          //this.logoUrl += data.logoUrl;
          data.logoUrl=this.logoUrl+data.logoUrl || '../../../assets/images/Logo-00.png';
        }
        this.institutionProfile = data as InstitutionProfile;
        // console.log('profileService', this.institutionProfile);
        this.instituteName = this.institutionProfile.institutionname;
        return data;
      }
      }),
      tap(e=>this.loaderService.isLoading.next(false)),
      tap(e=>console.log('test pipe'+e))
    )
  )
);

  ngOnDestroy() {
    console.log('unsubscribe');
    this.subscriptions.map(subscription => {
      subscription.unsubscribe()
    });
  }

}
