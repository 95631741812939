import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable, of } from 'rxjs'
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Institution} from './institution.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private httpClient: HttpClient) { }

  getByCode(code: string): Observable<Institution> {
    console.log(code);
    //this.httpOptions.headers.delete("InstitutionCode");
    return this.httpClient.get<Institution>(environment.apiUrl + '/institution/GetInstitutionDetailsByCode/' + code, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  
  errorHandler(error) {
     console.log(error.error.message);
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }


}
