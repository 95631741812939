// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// apiUrl: "https://edumaqapi.azurewebsites.net/api"
// apiUrl: "http://localhost:5000/api"
// apiUrl: "New"
export const environment = {
  production: true,

  //========LOCAL=============
  //  apiUrl: 'http://localhost:5001/api',
  //  imageUrl: 'http://localhost:5001',
  //  localUrl: 'http://localhost:4200/'

  //==================HTTP STAGING========================
//  apiUrl: 'http://stagingapi.edumaq.in/api',
//  imageUrl: 'http://stagingapi.edumaq.in'

 //========HTTPS STAGING=============
//  apiUrl: 'https://stagingapi.edumaq.in/api',
//  imageUrl: 'https://stagingapi.edumaq.in'

// ==================HTTP PRODUCTION======================
//  apiUrl: 'http://api.edumaq.in/api',
//  imageUrl: 'http://api.edumaq.in'

 //========HTTPS PRODUCTION=============
 apiUrl: 'https://api.edumaq.in/api',
 imageUrl: 'https://api.edumaq.in'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
